@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/scss/variables";

body
{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
}

.title
{
    color:$blue1;
}

html,
body,
#root{
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: black;
}

.editorbox
{
  display: flex;

  .ft-col
  {
    &.row2
    {
      display: flex;
      flex-direction: column;
      > *
      {
        flex:1;
      }
    }
  }
  
  &.col
  {
    flex-direction: row;
    height: 100%;
    //min-height: 100vh;
    overflow-y: hidden;
  }

  &.row
  {
    flex-direction: column;
    // min-height: auto;
    height:auto;
    overflow-y: hidden;
    overflow-x: hidden;

    .ft-col
    {
      height: 33vh;
      .json,.function
      {
        height:16vh;
      }
    }
    
    
  }

  
  // .editorbox
  // {
  //   height:calc( 100vh - 60px );
  // }
  > *
  {
    flex:1;
  }
}

.gray
{
  color:#999;
}

  